import {SetState} from 'zustand';
import type {IStore} from '..';

type IValue = {
    value: string | undefined
}

type IActions = {
    set: (action: IValue) => void
    unset: () => void
}

type ISlice = (set: SetState<IStore>) => IState;

export type IState = {order: IValue & IActions};

const initial: IValue = {value: undefined};

const order: ISlice = set => ({
    order: {
        ...initial,
        set: action => set(state => ({order: {...state.order, ...action}})),
        unset: () => set(state => ({order: {...state.order, ...initial}})),
    },
});

export default order;
