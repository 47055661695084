import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import Scroll from '@/components/scroll';
import useStore from '@/store/index';
import {scroll} from '@/utils/config';
import Nav from '../nav';
import styles from './header.module.css';

const Header: React.FC = () => {
    const router = useRouter();
    const unsetOrder = useStore(state => state.order.unset);

    return (
        <header className={styles.header} data-testid="component-header">
            <section className={styles.container}>
                {router.route !== '/' ? (
                    <Link href="/">
                        <a href="/">
                            <Image
                                src="/logo/logo_black.svg"
                                width={200}
                                height={40}
                                alt="JF Woodpall"
                            />
                        </a>
                    </Link>
                ) : (
                    <>
                        <Scroll
                            {...scroll}
                            to="hero"
                            offset={-100}
                            onClick={unsetOrder}
                        >
                            <Image
                                src="/logo/logo_black.svg"
                                width={200}
                                height={40}
                                alt="JF Woodpall"
                            />
                        </Scroll>
                        <Nav />
                    </>
                )}
            </section>
        </header>
    );
};

export default Header;
