import create from 'zustand';
import {devtools} from 'zustand/middleware';
import alert, {IState as IAlert} from './alert';
import locale, {IState as ILocale} from './locale';
import order, {IState as IOrder} from './order';

export type IStore = IAlert & ILocale & IOrder;

const useStore = create<IStore, [['zustand/devtools', never]]>(
    devtools(set => ({
        ...alert(set),
        ...locale(set),
        ...order(set),
    }))
);

export default useStore;
