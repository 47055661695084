import LocalesEnum from '@/enums/LocalesEnum';
import SectionsEnum from '@/enums/SectionsEnum';
import {ILocales} from './types';

const locales: ILocales = {
    [LocalesEnum.de]: {
        noscript: (
            <>
                <strong>You don&amp;t have JavaScript enabled</strong>
                <p>
                    Einige Teile der Website funktionieren für Sie möglicherweise nicht richtig.
                    <br />
                    Hier finden Sie{' '}
                    <a href="https://www.enable-javascript.com/de/" target="_blank" rel="noreferrer noopener">
                        Anleitungen
                    </a>
                    , wie Sie JavaScript in Ihrem Browser aktivieren können.
                </p>
            </>
        ),
        pages: {
            error: 'Hier liegt ein Fehler vor.',
            404: 'Leider existiert die Seite nicht.',
        },
        message: {
            successSave: 'Erfolgreich gespeichert.',
            successRemove: 'Erfolgreich entfernt.',
            successUpload: 'Erfolgreich hochgeladen.',
        },
        description: 'Wir verkaufen, produzieren und reparieren Holzpaletten mit der Garantie höchster Qualität.',
        types: [
            {
                key: SectionsEnum.sale,
                value: 'Verkauf von Holzpaletten',
            },
            {
                key: SectionsEnum.production,
                value: 'Produktion von atypischen Paletten',
            },
            {
                key: SectionsEnum.repair,
                value: 'Reparatur von Holzpaletten',
            },
        ],
        hero: {
            heading: (
                <>
                    Wir verkaufen, produzieren
                    <br />
                    und reparieren Holzpaletten
                    <br />
                    mit der Garantie höchster Qualität.
                </>
            ),
        },
        sections: {
            [SectionsEnum.sale]: {
                nav: 'Verkauf',
                title: 'Verkauf von Holzpaletten',
                content:
                    'Wir verkaufen EPAL- und EUR-Holzpaletten in Standardabmessungen oder stellen auf Kundenwunsch auch atypische Holzpaletten mit Garantie höchster Qualität her. Wenn es um die Auswahl der richtigen Holzpalette für Ihr Unternehmen geht, sind wir die beste Wahl. Unsere Europaletten entsprechen den EPAL-Vorschriften und sind ISO-zertifiziert.',
                button: 'Bestellungen',
            },
            [SectionsEnum.production]: {
                nav: 'Produktion',
                title: 'Produktion von atypischen Paletten',
                content:
                    'Sie sind sich bei Ihrer Wahl noch unschlüssig? Wir beraten Sie und entwerfen eine ideale atypische Palette nur für Sie. Atypische Paletten produzieren wir immer direkt nach Kundenwunsch. Die Größe, Konstruktion, Tragfähigkeit und viele andere Aspekte werden während der Produktion so ausgelegt, dass sie den Kundenanforderungen am besten entsprechen.',
                button: 'Bestellungen',
            },
            [SectionsEnum.repair]: {
                nav: 'Reparieren',
                title: 'Reparatur von Holzpaletten',
                content:
                    'Wir reparieren Paletten seit der Gründung unseres Unternehmens und gehören dank unserer Erfahrung zu den bewährtesten Anbietern auf unserem Markt. Wir reparieren Paletten aller Größen. Wir reparieren Paletten, indem wir beschädigte oder fehlende Teile ersetzen. Bei der Reparatur werden nur Teile verwendet, die für die Herstellung von Paletten zugelassen sind und daher haben die reparierten Paletten die Parameter und die Qualität der neuen Paletten.',
                button: 'Bestellungen',
            },
            [SectionsEnum.transport]: {
                nav: 'Transport',
                title: 'Transport',
                content:
                    'Wir stellen die gesamte Logistik mit unseren eigenen Fahrzeugen oder über unsere Transportpartner sicher. Sie können die Paletten auch persönlich abholen. Damit wir einwandfreie Ware liefern können, wird diese vor der Verladung einer gründlichen Prüfung unterzogen. Bei Feststellung einer Beschädigung wird die Ware nicht verladen, sondern gegen ein unbeschädigtes Stück ausgetauscht. Die komplette Logistik wickeln wir mit unseren eigenen Fahrzeugen oder über unsere Transportpartner ab.',
            },
            [SectionsEnum.form]: {
                nav: 'Bestellungen',
                title: 'Bestellungen',
            },
            [SectionsEnum.about]: {
                nav: 'Über uns',
                title: 'Über uns',
                button: 'Kontakt',
                content:
                    'Wir sind ein tschechisches Unternehmen mit Sitz in Ostrava Radvanice. Wir verkaufen und reparieren Holzpaletten mit der Garantie höchster Qualität. Wir spezialisieren uns auch auf die Produktion von atypischen Paletten, die wir nach Kundenwunsch herstellen. Unsere Europaletten entsprechen den Vorschriften der EPAL (European Pallet Association). EPAL-Paletten sind ISO (International Organization for Standardization) zertifiziert. Wir bieten vorteilhafte Preise für größere Bestellungen und einer langfristige Zusammenarbeit an.',
            },
            [SectionsEnum.contact]: {
                nav: 'Kontakt',
                title: 'Kontakt',
                info: {
                    title: 'Kontaktinformationen',
                    tel: (
                        <>
                            Tel.: <strong>+420 737 612 133</strong>
                            <br />
                        </>
                    ),
                    email: (
                        <>
                            E-mail: <strong>jasny@jfwoodpall.cz</strong>
                        </>
                    ),
                },
                address: {
                    title: 'Informationen zur Rechnungsanschrift',
                    content: (
                        <>
                            JF Woodpall s.r.o.
                            <br />
                            Šmídova 804/20
                            <br />
                            716 00 Ostrava-Radvanice
                            <br />
                            <br />
                            IČ: 14283034
                            <br />
                            DIČ: CZ14283034
                        </>
                    ),
                },
                services: {title: 'Angebot von Dienstleistungen'},
                end: 'Wir verkaufen, produzieren und reparieren Holzpaletten mit der Garantie höchster Qualität.',
            },
        },
        form: {
            input: {
                company: {
                    label: 'Firma',
                    error: 'Es wurden unzulässige Zeichen eingegeben.',
                },
                name: {
                    label: 'Name',
                    error: 'Es wurden unzulässige Zeichen eingegeben.',
                    required: 'Sie müssen einen Namen eingeben.',
                },
                email: {
                    label: 'E-mail',
                    error: 'Die E-Mail hat nicht das richtige Format.',
                    required: 'Es ist notwendig, Ihre E-Mail einzugeben.',
                },
                phone: {
                    label: 'Telefon',
                    error: 'Rufnummer ist ungültig.',
                },
                order: {label: 'Typ der Bestellung'},
                message: {
                    label: 'Nachricht',
                    error: 'Es wurden unzulässige Zeichen eingegeben.',
                },
                agreement: {
                    label: 'Ich bin mit der Verarbeitung meiner persönlichen Daten einverstanden.',
                    required: 'Sie müssen der Verarbeitung Ihrer personenbezogenen Daten zustimmen.',
                },
            },
            select: {default: 'Wählen Sie ...'},
            submit: 'Senden',
            paragraph: {
                heading: 'Oder per Telefon',
                phone: '+420 737 612 133',
            },
        },
        button: {close: 'Schließen'},
        api: {
            send: {
                subject: 'E-Mail von www.jf-woodpall.cz',
                heading: 'Wir verkaufen, produzieren und reparieren Holzpaletten mit der Garantie höchster Qualität.',
                subheading:
                    'Vielen Dank, dass Sie uns das Formular geschickt haben. Wir werden Sie in Kürze kontaktieren. Im Folgenden finden Sie eine Zusammenfassung der übermittelten Daten.',
                response: 'Ich danke Ihnen. Die Nachricht wurde erfolgreich gesendet.',
                notAllowed: 'Nicht erlaubte Methode',
            },
        },
    },
};

export default locales;
