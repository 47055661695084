import React from 'react';
import VariantsEnum from '@/enums/VariantsEnum';
import styles from './button.module.css';

type IOnlyIcon = {hasOnlyIcon?: false; title?: string} | {hasOnlyIcon?: true; title: string};

type IButton = {
    type?: 'button' | 'submit' | 'reset'
    disabled?: boolean
    variant?: VariantsEnum
    tabindex?: number
    className?: string
    isImportant?: boolean
    isGrouped?: boolean
    children: React.ReactNode
    onFocus?: () => void
    onClick?: (e: React.MouseEvent) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export type IProps = IOnlyIcon & IButton;

const Button = React.forwardRef<HTMLButtonElement, IProps>(({
    type = 'button',
    disabled,
    variant,
    title,
    tabindex,
    className = '',
    isImportant,
    isGrouped,
    hasOnlyIcon,
    children,
    onFocus,
    onClick,
    onKeyDown,
}, ref) => (
    <button
        ref={ref}
        disabled={disabled}
        type={type}
        tabIndex={tabindex}
        className={`${styles.button} ${variant ? styles[variant] : ''} ${isImportant ? styles.important : ''} ${isGrouped ? styles.isGrouped : ''} ${hasOnlyIcon ? styles.hasOnlyIcon : ''} ${className}`}
        aria-label={title}
        data-testid="component-button"
        onFocus={onFocus}
        onClick={onClick}
        onKeyDown={onKeyDown}
    >
        {children}
    </button>
));

Button.displayName = 'Button';

export default Button;
