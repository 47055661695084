enum SectionsEnum {
    about = 'about',
    sale = 'sale',
    production = 'production',
    repair = 'repair',
    transport = 'transport',
    form = 'form',
    contact = 'contact'
}

export default SectionsEnum;
