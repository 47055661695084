import React from 'react';
import Locales from '@/components/layout/locales';
import Scroll from '@/components/scroll';
import SectionsEnum from '@/enums/SectionsEnum';
import useLocale from '@/hooks/useLocale';
import useStore from '@/store/index';
import {scroll} from '@/utils/config';
import styles from './nav.module.css';

const Nav: React.FC = () => {
    const locale = useLocale();
    const unsetOrder = useStore(state => state.order.unset);

    return (
        <nav className={styles.nav} data-testid="component-nav">
            <ul className={styles.list}>
                {Object.keys(locale?.sections).filter(item => locale.sections[item as SectionsEnum].nav).map(item => (
                    <li key={item} className={styles.item}>
                        <Scroll
                            {...scroll}
                            to={item}
                            offset={-100}
                            activeClass={styles.active}
                            className={styles.button}
                            onClick={unsetOrder}
                        >
                            {locale.sections[item as SectionsEnum].nav}
                        </Scroll>
                    </li>
                ))}
            </ul>
            <Locales />
        </nav>
    );
};

export default Nav;
