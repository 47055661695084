import cs from './cs';
import de from './de';
import it from './it';
import {ILocales} from './types';

const locales: ILocales = {
    ...cs,
    ...de,
    ...it,
};

export default locales;
