import React from 'react';
import {ScrollLink} from 'react-scroll';
import Button from '@/UI/form-control/button';

type IProps = {
    className?: string
    asButton?: boolean
    children: React.ReactNode
}

const Scroll: React.FC<IProps> = ({...props}) => (
    <>
        {props.asButton ? (
            <Button {...props} className={props.className}>{props.children}</Button>
        ) : (
            <button {...props} type="button" className={props.className}>{props.children}</button>
        )}
    </>
);

export default ScrollLink(Scroll);
