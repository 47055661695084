import styles from './section.module.css';

type IProps = {
    children?: React.ReactNode
}

const Section: React.FC<IProps> = ({children}) => (
    <section className={styles.section} data-testid="component-section">
        {children}
    </section>
);

export default Section;
