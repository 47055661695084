import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React from 'react';
import styles from './locales.module.css';

const Locales: React.FC = () => {
    const router = useRouter();

    return (
        <ul className={styles.locales} data-testid="component-locales">
            {router.locales && router.locales.map(item => (
                <li key={item} className={styles.item}>
                    <Link href={router.route} locale={item}>
                        <a
                            href={router.route}
                            className={`${styles.anchor} ${item === router.locale ? styles.disabled : ''}`}
                        >
                            <Image
                                priority
                                src={`/icons/${item}.svg`}
                                width={24}
                                height={16}
                                alt={item.toUpperCase()}
                            />
                            <span className="sr-only">
                                {item.toUpperCase()}
                            </span>
                        </a>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default Locales;
