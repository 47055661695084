import {SetState} from 'zustand';
import {ILocales} from '@/data/locales/types';
import type {IStore} from '..';

type IValue = {
    value: ILocales | undefined
    timestamp: number | undefined
}

type IActions = {
    set: (action: IValue) => void
    unset: () => void
}

type ISlice = (set: SetState<IStore>) => IState;

export type IState = {locale: IValue & IActions};

const initial: IValue = {
    value: undefined,
    timestamp: undefined,
};

const locale: ISlice = set => ({
    locale: {
        ...initial,
        set: action => set(state => ({locale: {...state.locale, ...action}})),
        unset: () => set(state => ({locale: {...state.locale, ...initial}})),
    },
});

export default locale;
