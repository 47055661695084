import LocalesEnum from '@/enums/LocalesEnum';
import SectionsEnum from '@/enums/SectionsEnum';
import {ILocales} from './types';

const locales: ILocales = {
    [LocalesEnum.cs]: {
        noscript: (
            <>
                <strong>Nemáte zapnutý JavaScript</strong>
                <p>
                    Některé součásti stránek Vám nemusí fungovat správně.
                    <br />
                    Zde jsou{' '}
                    <a href="https://www.enable-javascript.com/cz/" target="_blank" rel="noreferrer noopener">
                        {' '}
                        instrukce
                    </a>{' '}
                    jak povolit JavaScript ve Vašem webovém prohlížeči.
                </p>
            </>
        ),
        pages: {
            error: 'Někde se stala chyba.',
            404: 'Stránka bohužel neexistuje.',
        },
        message: {
            successSave: 'Úspěsně uloženo.',
            successRemove: 'Úspěšně odstraněno.',
            successUpload: 'Úspěšně nahráno.',
        },
        description: 'Prodáváme, vyrábíme a opravujeme dřevěné palety s garancí té nejvyšší kvality.',
        types: [
            {
                key: SectionsEnum.sale,
                value: 'Prodej dřevěných palet',
            },
            {
                key: SectionsEnum.production,
                value: 'Výroba atypických palet',
            },
            {
                key: SectionsEnum.repair,
                value: 'Oprava dřevěných palet',
            },
        ],
        hero: {
            heading: (
                <>
                    Prodáváme, vyrábíme
                    <br />a opravujeme dřevěné palety
                    <br />s garancí té nejvyšší kvality.
                </>
            ),
        },
        sections: {
            [SectionsEnum.sale]: {
                nav: 'Prodej',
                title: 'Prodej dřevěných palet',
                content: `Prodáváme dřevěné palety EPAL a EUR ve standardních rozměrech nebo na přání zákazníka
                vyrábíme i dřevěné atypické palety s garancí té nejvyšší kvality. Pokud jde o výběr správné dřevěné
                palety pro vaše podnikání, jsme ta nejlepší volba. Naše Euro palety splňují předpisy EPAL a mají
                certifikaci ISO.`,
                button: 'Objednávka',
            },
            [SectionsEnum.production]: {
                nav: 'Výroba',
                title: 'Výroba atypických palet',
                content:
                    'Nejste si jistí Vaším výběrem? Poradíme Vám a navrhneme ideální atypickou paletu přímo pro Vás. Atypické palety vyrábíme vždy dle požadavků zákazníka. Jejich rozměr, provedení, nosnost a řada dalších aspektů jsou při výrobě konstruovány tak, aby co nejlépe odpovídaly potřebám zákazníka.',
                button: 'Objednávka',
            },
            [SectionsEnum.repair]: {
                nav: 'Oprava',
                title: 'Oprava dřevěných palet',
                content: `Opravou palet se zabýváme od samého vzniku naší společnosti a díky našim zkušenostem patříme
                mezi prověřené dodavatele na našem trhu. Opravujeme také palety libovolného rozměru. Palety opravujeme
                nahrazením poškozených nebo chybějících dílů. Při opravě jsou použity pouze díly, které jsou přípustné
                k výrobě palet a opravené palety mají parametry a kvalitu nových palet.`,
                button: 'Objednávka',
            },
            [SectionsEnum.transport]: {
                nav: 'Doprava',
                title: 'Doprava',
                content: `Veškerou logistiku zajišťujeme vlastními vozidly nebo prostřednictvím našich přepravních
                partnerů. Palety si můžete také vyzvednout osobně. Abychom Vám doručovali bezvadné zboží, prochází před
                naložením důkladnou kontrolou. Při odhalení poškození není pak toto zboží naloženo, ale vymění se za jiný, nepoškozený kus.`,
            },
            [SectionsEnum.form]: {
                nav: 'Objednávka',
                title: 'Příjem objednávek',
            },
            [SectionsEnum.about]: {
                nav: 'O nás',
                title: 'O nás',
                content:
                    'Jsme česká firma sídlící v Ostravě Radvanicích. Prodáváme a opravujeme dřevěné palety s garancí té nejvyšší kvality. Také se specializujeme na výrobu atypických palet, které vyrábíme vždy dle požadavků zákazníka. Naše Euro palety splňují předpisy EPAL (European Pallet Association). Palety EPAL jsou certifikovány ISO (Mezinárodní organizace pro normalizaci). Pro větší odběry a dlouhodobou spolupráci nabízíme výhodné ceny. Veškerou logistiku zajišťujeme vlastními vozidly nebo prostřednictvím našich přepravních partnerů.',
                button: 'Kontakty',
            },
            [SectionsEnum.contact]: {
                nav: 'Kontakt',
                title: 'Kontakty',
                info: {
                    title: 'Kontaktní informace',
                    tel: (
                        <>
                            Tel.: <strong>+420 737 612 133</strong>
                            <br />
                        </>
                    ),
                    email: (
                        <>
                            E-mail: <strong>jasny@jfwoodpall.cz</strong>
                        </>
                    ),
                },
                address: {
                    title: 'Fakturační údaje',
                    content: (
                        <>
                            JF Woodpall s.r.o.
                            <br />
                            Šmídova 804/20
                            <br />
                            716 00 Ostrava-Radvanice
                            <br />
                            <br />
                            IČ: 14283034
                            <br />
                            DIČ: CZ14283034
                        </>
                    ),
                },
                services: {title: 'Nabídka služeb'},
                end: 'Prodáváme, vyrábíme a opravujeme dřevěné palety s garancí té nejvyšší kvality.',
            },
        },
        form: {
            input: {
                company: {
                    label: 'Firma',
                    error: 'Jsou zadány nepovolené znaky.',
                },
                name: {
                    label: 'Jméno',
                    error: 'Jsou zadány nepovolené znaky.',
                    required: 'Je nutné zadat jméno.',
                },
                email: {
                    label: 'E-mail',
                    error: 'E-mail není zadán ve správném formátu.',
                    required: 'Je nutné zadat e-mail.',
                },
                phone: {
                    label: 'Telefon',
                    error: 'Telefon není ve správném tvaru.',
                },
                order: {label: 'Druh objednávky'},
                message: {
                    label: 'Zpráva',
                    error: 'Jsou zadány nepovolené znaky.',
                },
                agreement: {
                    label: 'Souhlasím se zpracováním osobních údajů.',
                    required: 'Je potřeba souhlasit se zpracováním osobních údajů.',
                },
            },
            select: {default: 'Vyberte ...'},
            submit: 'Odeslat',
            paragraph: {
                heading: 'Nebo telefonicky',
                phone: '+420 737 612 133',
            },
        },
        button: {close: 'Zavřít'},
        api: {
            send: {
                subject: 'E-mail z webu www.jf-woodpall.cz',
                heading: 'Prodáváme, vyrábíme a opravujeme dřevěné palety s garancí té nejvyšší kvality.',
                subheading: 'Děkujeme za zaslání formuláře. V nejbližší době Vás budeme kontaktovat. Níže rekapitulace zaslaných dat.',
                response: 'Děkujeme. Zpráva byla úspěšně odeslána.',
                notAllowed: 'Není povoleno',
            },
        },
    },
};

export default locales;
