import LocalesEnum from '@/enums/LocalesEnum';
import SectionsEnum from '@/enums/SectionsEnum';
import {ILocales} from './types';

const locales: ILocales = {
    [LocalesEnum.it]: {
        noscript: (
            <>
                <strong>Non è abilitato JavaScript</strong>
                <p>
                    Alcune parti del sito potrebbero non funzionare correttamente per voi.
                    <br />
                    Ecco le{' '}
                    <a href="https://www.enable-javascript.com/it/" target="_blank" rel="noreferrer noopener">
                        {' '}
                        istruzioni
                    </a>{' '}
                    su come abilitare JavaScript in il browser web.
                </p>
            </>
        ),
        pages: {
            error: `
                C'è stato un errore da qualche parte.
            `,
            404: 'Purtroppo la pagina non esiste.',
        },
        message: {
            successSave: 'Memorizzato con successo.',
            successRemove: 'Rimosso con successo.',
            successUpload: 'Caricato con successo.',
        },
        description: `Vendiamo, fabbrichiamo e ripariamo i bancali di legno con la garanzia della massima
        qualità.`,
        types: [
            {
                key: SectionsEnum.sale,
                value: 'Vendita dei bancali di legno',
            },
            {
                key: SectionsEnum.production,
                value: 'Produzione dei bancali di legno atipici',
            },
            {
                key: SectionsEnum.repair,
                value: 'Riparazione dei bancali di legno',
            },
        ],
        hero: {
            heading: (
                <>
                    Vendiamo, fabbrichiamo
                    <br />e ripariamo i bancali di legno
                    <br />
                    con la garanzia della massima qualità.
                </>
            ),
        },
        sections: {
            [SectionsEnum.sale]: {
                nav: 'Vendita',
                title: 'Vendita dei bancali di legno',
                content: `Vendiamo i pallet in legno EPAL ed EUR di dimensioni standard oppure, su richiesta
                del cliente, produciamo anche i pallet atipici in legno con garanzia di altissima
                qualità. Quando si tratta di scegliere il pallet in legno giusto per la vostra attività,
                siamo la scelta migliore. I nostri europallet sono conformi alle normative EPAL e
                sono certificati ISO.`,
                button: 'Ordine',
            },
            [SectionsEnum.production]: {
                nav: 'Produzione',
                title: 'Produzione dei bancali di legno atipici',
                content: `Non siete sicuri della vostra scelta? Vi consiglieremo e progetteremo dei pallet atipici
                ideali solo per voi. Produciamo i pallet atipici sempre in base alle esigenze del
                cliente, le dimensioni, design, capacità di carico e molti altri aspetti sono pensati già
                dalla produzione per soddisfare al meglio le esigenze del cliente.`,
                button: 'Ordine',
            },
            [SectionsEnum.repair]: {
                nav: 'Riparazione',
                title: 'Riparazione dei bancali di legno',
                content: `Nella nostra azienda ci occupiamo delle riparazioni dei pallet dall'inizio della nostra
                attività e grazie alla nostra esperienza, siamo uno dei fornitori affermati nel nostro
                campo. Ripariamo anche i pallet di qualsiasi dimensione sostituendo parti
                danneggiate o mancanti. Durante la riparazione vengono utilizzate solo le parti
                consentite per la produzione dei pallet, così riparati hanno i parametri e la qualità dei
                nuovi pallet.`,
                button: 'Ordine',
            },
            [SectionsEnum.transport]: {
                nav: 'Trasporto',
                title: 'Trasporto',
                content: `Forniamo tutta la logistica con i nostri veicoli o tramite i nostri partner di trasporto. È
                possibile ritirare i pallet anche di persona. Affinché possiamo consegnare la merce
                perfetta, viene sottoposta a un'ispezione approfondita prima del carico. Quando si
                scopre un danno, la merce non viene caricata, ma viene sostituita con un altro pezzo
                non danneggiato.`,
            },
            [SectionsEnum.form]: {
                nav: 'Ordine',
                title: 'Ricezione degli ordini',
            },
            [SectionsEnum.about]: {
                nav: 'Chi siamo',
                title: 'Chi siamo',
                content: `Siamo una società ceca con sede a Ostrava Radvanice. Vendiamo e ripariamo i
                bancali di legno con una garanzia di altissima qualità. Inoltre, siamo specializzati
                nella produzione dei pallet atipici, che produciamo sempre in base alle esigenze del
                cliente. I nostri europallet sono conformi alle normative EPAL (European Pallet
                Association). I pallet EPAL sono certificati ISO (International Organization for
                Standardization). Offriamo prezzi vantaggiosi per gli ordini più grandi e una
                cooperazione a lungo termine. Forniamo tutta la logistica con i nostri veicoli o tramite
                i nostri partner di trasporto.`,
                button: 'Contatto',
            },
            [SectionsEnum.contact]: {
                nav: 'Contatto',
                title: 'Contatto',
                info: {
                    title: 'Informazioni di contatto',
                    tel: (
                        <>
                            Tel.: <strong>+420 737 612 133</strong>
                            <br />
                        </>
                    ),
                    email: (
                        <>
                            E-mail: <strong>jasny@jfwoodpall.cz</strong>
                        </>
                    ),
                },
                address: {
                    title: 'Informazioni sulla fatturazione',
                    content: (
                        <>
                            JF Woodpall s.r.o.
                            <br />
                            Šmídova 804/20
                            <br />
                            716 00 Ostrava-Radvanice
                            <br />
                            <br />
                            IČ: 14283034
                            <br />
                            DIČ: CZ14283034
                        </>
                    ),
                },
                services: {title: 'Offerta di servizi'},
                end: `Vendiamo, fabbrichiamo e ripariamo i bancali di legno con la garanzia della massima
                qualità.`,
            },
        },
        form: {
            input: {
                company: {
                    label: 'Azienda',
                    error: 'Vengono inseriti caratteri non autorizzati.',
                },
                name: {
                    label: 'Nome',
                    error: 'Vengono inseriti caratteri non autorizzati.',
                    required: 'È necessario inserire un nome.',
                },
                email: {
                    label: 'E-mail',
                    error: `
                        L'e-mail non è nel formato corretto.
                    `,
                    required: 'È necessario inserire il proprio indirizzo e-mail.',
                },
                phone: {
                    label: 'Telefono',
                    error: 'Il numero di telefono non è nel formato correcto.',
                },
                order: {label: 'Tipo di ordine'},
                message: {
                    label: 'Messaggio',
                    error: 'Vengono inseriti caratteri non autorizzati.',
                },
                agreement: {
                    label: 'Acconsento al trattamento dei miei dati personali.',
                    required: `
                        L'utente deve acconsentire al trattamento dei dati personali.
                    `,
                },
            },
            select: {default: 'Selezionare ...'},
            submit: 'Inviare',
            paragraph: {
                heading: 'O per telefono',
                phone: '+420 737 612 133',
            },
        },
        button: {close: 'Chiudere'},
        api: {
            send: {
                subject: 'E-mail da www.jf-woodpall.cz',
                heading: `Vendiamo, fabbrichiamo e ripariamo i bancali di legno con la garanzia della massima
                qualità.`,
                subheading: 'Grazie per averci inviato il modulo. Vi contatteremo a breve. Di seguito un riepilogo dei dati inviati.',
                response: 'Grazie. Il messaggio è stato inviato con successo.',
                notAllowed: 'Non consentito',
            },
        },
    },
};

export default locales;
