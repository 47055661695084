import LocalesEnum from '@/enums/LocalesEnum';

type AnalyticsType = {
    [key: string]: {
        ga: string
        ua: string
        ads: string
        send: string
    }
}

const AnalyticsDistributor: AnalyticsType = {
    [LocalesEnum.cs]: {
        ga: 'G-ZS72WH2V56',
        ua: 'UA-232489680-1',
        ads: 'AW-10936425038',
        send: 'AW-10936425038/yLYfCLba5ccDEM608t4o',
    },
    [LocalesEnum.de]: {
        ga: 'G-452VBNLVY4',
        ua: 'UA-232498892-1',
        ads: 'AW-10936367161',
        send: 'AW-10936367161/MUvwCLXV58cDELnw7t4o',
    },
    [LocalesEnum.it]: {
        ga: 'G-6B30DMQZS9',
        ua: 'UA-232503353-1',
        ads: 'AW-10936457162',
        send: 'AW-10936457162/tNaJCMn95scDEMqv9N4o',
    },
};

export default AnalyticsDistributor;
