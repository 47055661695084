const API = typeof window !== 'undefined'
    ? `${window.location.origin}${process.env.NEXT_PUBLIC_API}`
    : process.env.NEXT_PUBLIC_API;

export const scroll = {
    ignoreCancelEvents: false,
    spy: true,
    smooth: 'easeInOutQuart',
    duration: 1200,
};

export const countries: {[key: string]: string} = {
    cs: 'cz',
    de: 'de',
    it: 'it',
};

export default API;
