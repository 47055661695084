import {LazyMotion, domAnimation} from 'framer-motion';
import dynamic from 'next/dynamic';
import React from 'react';
import countTimeout from '@/helpers/countTimeout';
import getResponseVariant from '@/helpers/getResponseVariant';
import useLocale from '@/hooks/useLocale';
import useStore from '@/store/index';
import Header from './header';
import Meta from './meta';
import Section from './section';
import styles from './layout.module.css';

const Alert = dynamic(() => import('@/UI/alert'));
const Footer = dynamic(() => import('@/components/layout/footer'));
const Form = dynamic(() => import('@/components/form'));
const Hero = dynamic(() => import('@/components/hero'));

type IProps = {
    title?: string
    description?: string
    hasError?: boolean
    className?: string
    children: React.ReactNode
}

const Layout: React.FC<IProps> = ({
    title,
    description,
    hasError,
    className = '',
    children,
}) => {
    const {status, message} = useStore(state => ({status: state.alert.status, message: state.alert.message}));
    const locale = useLocale();

    return (
        <>
            <Meta title={title} description={description} />
            <main className={`${styles.main} ${className}`}>
                <LazyMotion strict features={domAnimation}>
                    <Header />
                    {!hasError && <Hero />}
                    <Section>
                        <noscript>
                            {locale.noscript}
                        </noscript>
                        {children}
                    </Section>
                    {!hasError && <Form />}
                    <Footer />
                </LazyMotion>

                <Alert
                    isImportant
                    isVisible={!!message}
                    variant={status ? getResponseVariant(status) : undefined}
                    className={styles.alert}
                    timeout={message ? countTimeout(message) : 3}
                >
                    {message}
                </Alert>
            </main>
        </>
    );
};

export default Layout;
