import {useRouter} from 'next/router';
import Script from 'next/script';
import React from 'react';
import AnalyticsDistributor from '@/distributors/AnalyticsDistributor';
import LocalesEnum from '@/enums/LocalesEnum';

const Analytics: React.FC = () => {
    const router = useRouter();

    return (
        <>
            <Script
                async
                id="tagManager72"
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${AnalyticsDistributor[router.locale || LocalesEnum.cs].ga}`}
                crossOrigin="anonymous"
            />
            <Script id="dataLayer72" strategy="afterInteractive">
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${AnalyticsDistributor[router.locale || LocalesEnum.cs].ga}');`}
            </Script>
            <Script
                id="tagManager23"
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${AnalyticsDistributor[router.locale || LocalesEnum.cs].ua}`}
                crossOrigin="anonymous"
            />
            <Script id="dataLayer23" strategy="afterInteractive">
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${AnalyticsDistributor[router.locale || LocalesEnum.cs].ua}');`}
            </Script>
            <Script
                id="tagManager10"
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${AnalyticsDistributor[router.locale || LocalesEnum.cs].ads}`}
                crossOrigin="anonymous"
            />
            <Script id="dataLayer10" strategy="afterInteractive">
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${AnalyticsDistributor[router.locale || LocalesEnum.cs].ads}');`}
            </Script>
            <Script id="gtag" strategy="afterInteractive">
                {`gtag('event', 'page_view', {
                    'send_to': '${AnalyticsDistributor[router.locale || LocalesEnum.cs].ads}',
                    'value': 'replace with value',
                    'items': [{
                    'id': 'replace with value',
                    'location_id': 'replace with value',
                    'google_business_vertical': 'custom'
                    }]
                });`}
            </Script>
        </>
    );
};

export default Analytics;
